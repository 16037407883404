<template>
  <div style="padding: 0 20px">
    <div
      v-if="correntAnswer == false && incorrentAnswer == false"
      style="
        margin-top: 20px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 50px;
      "
    >
      How to make £{{ formatPrice(coinsGame.productPrice) }} with the coins
      below?
    </div>
    <div
      v-if="correntAnswer == false && incorrentAnswer == false"
      class="bonusPoints__currencies"
    >
      <div
        class="bonusPoints__currencies__item"
        @click="move(1)"
      >
        <img
          id="currency1"
          class="bonusPoints__currencies__item__currency"
          src="../../assets/images/currency.png"
          :class="{ bonusPoints__currencies__up: moved1 }"
          alt=""
        >
        <div
          class="bonusPoints__currencies__item__value"
          :class="{ bonusPoints__currencies__currencyUp: moved1 }"
        >
          £{{ formatPrice(price1) }}
        </div>
      </div>
      <div
        class="bonusPoints__currencies__item"
        @click="move(2)"
      >
        <img
          id="currency2"
          class="bonusPoints__currencies__item__currency"
          src="../../assets/images/currency.png"
          :class="{ bonusPoints__currencies__up: moved2 }"
          alt=""
        >
        <div
          class="bonusPoints__currencies__item__value"
          :class="{ bonusPoints__currencies__currencyUp: moved2 }"
        >
          £{{ formatPrice(price2) }}
        </div>
      </div>
      <div
        class="bonusPoints__currencies__item"
        @click="move(3)"
      >
        <img
          id="currency3"
          class="bonusPoints__currencies__item__currency"
          src="../../assets/images/currency.png"
          :class="{ bonusPoints__currencies__up: moved3 }"
          alt=""
        >
        <div
          class="bonusPoints__currencies__item__value"
          :class="{ bonusPoints__currencies__currencyUp: moved3 }"
        >
          £{{ formatPrice(price3) }}
        </div>
      </div>
      <div
        class="bonusPoints__currencies__item"
        @click="move(4)"
      >
        <img
          id="currency4"
          class="bonusPoints__currencies__item__currency"
          src="../../assets/images/currency.png"
          :class="{ bonusPoints__currencies__up: moved4 }"
          alt=""
        >
        <div
          class="bonusPoints__currencies__item__value"
          :class="{ bonusPoints__currencies__currencyUp: moved4 }"
        >
          £{{ formatPrice(price4) }}
        </div>
      </div>
      <div
        class="bonusPoints__currencies__item"
        @click="move(5)"
      >
        <img
          id="currency5"
          class="bonusPoints__currencies__item__currency"
          src="../../assets/images/currency.png"
          :class="{ bonusPoints__currencies__up: moved5 }"
          alt=""
        >
        <div
          class="bonusPoints__currencies__item__value"
          :class="{ bonusPoints__currencies__currencyUp: moved5 }"
        >
          £{{ formatPrice(price5) }}
        </div>
      </div>
    </div>
    <AppButton
      v-if="
        (moved1 || moved2 || moved3 || moved4 || moved5) &&
          correntAnswer == false &&
          incorrentAnswer == false
      "
      text="Answer"
      color="red"
      class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-sm font-bold"
      :mini="true"
      style="
        width: 90%;
        position: fixed;
        bottom: 70px;
        left: 0;
        right: 0;
        margin: 0 auto;
      "
      @click="evaluateGame"
    />
    <AppButton
      v-if="
        !moved1 &&
          !moved2 &&
          !moved3 &&
          !moved4 &&
          !moved5 &&
          correntAnswer == false &&
          incorrentAnswer == false
      "
      text="Answer"
      :mini="true"
      class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-sm font-bold"
      style="
        width: 90%;
        position: fixed;
        bottom: 70px;
        left: 0;
        right: 0;
        margin: 0 auto;
      "
    />
    <!-- <div
      v-if="correntAnswer == false && incorrentAnswer == false"
      class="bonusPoints__skip"
      @click="skipThisTime"
    >
      Skip this time
    </div> -->
    <div
      v-if="correntAnswer == true"
      style="
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: var(--main);
      "
    >
      <div>Correct!</div>
      <div>+{{ winPoints }} Sonik Points</div>
      <AppButton
        text="Next"
        class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-sm font-bold"
        color="blue"
        :mini="true"
        style="
          width: 90%;
          position: fixed;
          bottom: 70px;
          left: 0;
          right: 0;
          margin: 0 auto;
        "
        @click="openCheckoutGame"
      />
    </div>
    <div
      v-if="incorrentAnswer == true"
      style="
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: var(--red);
      "
    >
      <div>Incorrect!</div>
      <div>No Sonik Points</div>
      <AppButton
        text="Next"
        class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-sm font-bold"
        color="red"
        :mini="true"
        style="
          width: 90%;
          position: fixed;
          bottom: 70px;
          left: 0;
          right: 0;
          margin: 0 auto;
        "
        @click="openCheckoutGame"
      />
    </div>
  </div>
</template>

<script>
  import AppButton from "../../components/AppButton";
  import { mapActions } from 'vuex';

  export default {
    components: {
      AppButton,
    },
    props: {
      name: {
        type: String,
        default: "",
      },
      description: {
        type: String,
        default: "",
      },
      defaultPicture: {
        type: String,
        default: "",
      },
      productPrice: {
        // type: Number,
        // default: 0
      },
    },
    data() {
      return {
        correntAnswer: false,
        incorrentAnswer: false,
        checkoutModal: false,
        moved1: false,
        moved2: false,
        moved3: false,
        moved4: false,
        moved5: false,
        price1: null,
        price2: null,
        price3: null,
        price4: null,
        price5: null,
        winPoints: 0,
      };
    },
    computed: {
      coinsGame() {
        return JSON.parse(localStorage.getItem("coinsGame"));
      },
    },
    created() {
      const user = {
            userId: localStorage.getItem("children_user_id"),
            token: localStorage.getItem("children_token"),
            family_id_check: localStorage.getItem("children_family_id"),
        };
        this.fetchProfile();
        this.setupUser(user);
      this.generateSumsCurrenciesNumber(this.coinsGame.productPrice);
    },
    methods: {
      ...mapActions([
        "fetchProfile",
        "setupUser"
      ]),
      skipThisTime() {
        this.correntAnswer = true;
      },
      openCheckoutGame() {
        const productObj = {
          selectedSection: "gameCheck",
          fromGoalOrStore: this.coinsGame.from,
          name: this.coinsGame.name,
          description: this.coinsGame.description,
          productPrice: Number(this.coinsGame.productPrice).toFixed(2),
          defaultPicture: this.coinsGame.defaultPicture,
          productId: this.coinsGame.productId,
          from: this.coinsGame.from,
          goalId: this.coinsGame.goalId,
        };

        console.log("from coins game page", productObj);

        localStorage.setItem("checkoutGame", JSON.stringify(productObj));
console.log(this.coinsGame.from);
        if (this.coinsGame.from == "wishlist") {
          this.$router.replace({
                name: "children-checkout_game_wishlist",
            });
        } else if (this.coinsGame.from == "shop") {
          this.$router.replace({
                name: "children-checkout_game_shop",
            });
        } else if (this.coinsGame.from == "goals") {
          this.$router.replace({
                name: "children-checkout_game_goal",
            });
        }

        //   this.$emit("openCheckoutGame");
      },
      move(index) {
        if (index == 1) this.moved1 = !this.moved1;
        else if (index == 2) this.moved2 = !this.moved2;
        else if (index == 3) this.moved3 = !this.moved3;
        else if (index == 4) this.moved4 = !this.moved4;
        else if (index == 5) this.moved5 = !this.moved5;
      },
      evaluateGame() {
        var sum = 0;
        if (this.moved1 == true) sum = sum + Number(this.price1);
        if (this.moved2 == true) sum = sum + Number(this.price2);
        if (this.moved3 == true) sum = sum + Number(this.price3);
        if (this.moved4 == true) sum = sum + Number(this.price4);
        if (this.moved5 == true) sum = sum + Number(this.price5);

        if (sum == this.coinsGame.productPrice) {
          this.winPoints = (this.coinsGame.productPrice / 2).toFixed(2);
          this.$store.state.currentOrderSonikPoints = Number(this.winPoints);
          // this.addPoints(this.winPoints);
          // console.log("win points");
          // console.log(this.winPoints);
          this.correntAnswer = true;
        } else {
          this.incorrentAnswer = true;
        }
      },
      generateSumsCurrenciesNumber() {
        // console.log(price);
        // var min = 2;
        // var max = 3;
        // var random = Math.random() * (+max - +min) + +min;
        // this.generateSums(Number(random).toFixed(0));
        this.generateSums(2);
      },
      generateRandom(min, max, remove) {
        var num = Math.floor(Math.random() * (max - min + 1)) + min;
        return num == remove ? this.generateRandom(min, max, remove) : num;
      },
      generateSums(number) {
        this.price1 = null;
        this.price2 = null;
        this.price3 = null;
        this.price4 = null;
        this.price5 = null;
        if (number == 2) {
          var min = 0.25;
          var max = this.coinsGame.productPrice - 0.25;
          var randomPrice = Math.random() * (+max - +min) + +min;
          // Random pickup moneds
          var randomMoned = this.generateRandom(1, 5, 6);
          if (randomMoned == 1) this.price1 = randomPrice.toFixed(2);
          else if (randomMoned == 2) this.price2 = randomPrice.toFixed(2);
          else if (randomMoned == 3) this.price3 = randomPrice.toFixed(2);
          else if (randomMoned == 4) this.price4 = randomPrice.toFixed(2);
          else if (randomMoned == 5) this.price5 = randomPrice.toFixed(2);

          var randomMoned2 = this.generateRandom(1, 5, randomMoned);
          if (randomMoned2 == 1)
            this.price1 = (
              this.coinsGame.productPrice - randomPrice.toFixed(2)
            ).toFixed(2);
          else if (randomMoned2 == 2)
            this.price2 = (
              this.coinsGame.productPrice - randomPrice.toFixed(2)
            ).toFixed(2);
          else if (randomMoned2 == 3)
            this.price3 = (
              this.coinsGame.productPrice - randomPrice.toFixed(2)
            ).toFixed(2);
          else if (randomMoned2 == 4)
            this.price4 = (
              this.coinsGame.productPrice - randomPrice.toFixed(2)
            ).toFixed(2);
          else if (randomMoned2 == 5)
            this.price5 = (
              this.coinsGame.productPrice - randomPrice.toFixed(2)
            ).toFixed(2);

          // console.log(randomMoned);
          // console.log(randomMoned2);

          let case1 = false;
          let case2 = false;
          let case3 = false;
          if (this.price1 == null) {
            // console.log(11);
            if (case1 == false) {
              this.price1 = (
                (randomPrice.toFixed(2) / 2 + 0.25).toFixed(2) / 3
              ).toFixed(2);
              case1 = true;
            } else if (case2 == false) {
              this.price1 = (randomPrice.toFixed(2) / 5 + 0.73).toFixed(2);
              case2 = true;
            } else if (case3 == false) {
              this.price1 = (
                (this.coinsGame.productPrice - randomPrice.toFixed(2)).toFixed(
                  2
                ) /
                  2 +
                0.65
              ).toFixed(2);
              case3 = true;
            }
          }
          if (this.price2 == null) {
            // console.log(22);
            if (case1 == false) {
              this.price2 = (
                (randomPrice.toFixed(2) / 2 + 0.25).toFixed(2) / 3
              ).toFixed(2);
              case1 = true;
            } else if (case2 == false) {
              this.price2 = (randomPrice.toFixed(2) / 5 + 0.73).toFixed(2);
              case2 = true;
            } else if (case3 == false) {
              this.price2 = (
                (this.coinsGame.productPrice - randomPrice.toFixed(2)).toFixed(
                  2
                ) /
                  2 +
                0.04
              ).toFixed(2);
              case3 = true;
            }
          }
          if (this.price3 == null) {
            // console.log(33);
            if (case1 == false) {
              this.price3 = (
                (randomPrice.toFixed(2) / 2 + 0.25).toFixed(2) / 3
              ).toFixed(2);
              case1 = true;
            } else if (case2 == false) {
              this.price3 = (randomPrice.toFixed(2) / 5 + 0.73).toFixed(2);
              case2 = true;
            } else if (case3 == false) {
              this.price3 = (
                (this.coinsGame.productPrice - randomPrice.toFixed(2)).toFixed(
                  2
                ) /
                  2 +
                0.31
              ).toFixed(2);
              case3 = true;
            }
          }
          if (this.price4 == null) {
            // console.log(44);
            if (case1 == false) {
              this.price4 = (
                (randomPrice.toFixed(2) / 2 + 0.25).toFixed(2) / 3
              ).toFixed(2);
              case1 = true;
            } else if (case2 == false) {
              this.price4 = (randomPrice.toFixed(2) / 5 + 0.73).toFixed(2);
              case2 = true;
            } else if (case3 == false) {
              this.price4 = (
                (this.coinsGame.productPrice - randomPrice.toFixed(2)).toFixed(
                  2
                ) /
                  2 +
                0.61
              ).toFixed(2);
              case3 = true;
            }
          }
          if (this.price5 == null) {
            // console.log(55);
            if (case1 == false) {
              this.price5 = (
                (randomPrice.toFixed(2) / 2 + 0.25).toFixed(2) / 3
              ).toFixed(2);
              case1 = true;
            } else if (case2 == false) {
              this.price5 = (randomPrice.toFixed(2) / 5 + 0.73).toFixed(2);
              case2 = true;
            } else if (case3 == false) {
              this.price5 = (
                (this.coinsGame.productPrice - randomPrice.toFixed(2)).toFixed(
                  2
                ) /
                  2 +
                0.01
              ).toFixed(2);
              case3 = true;
            }
          }
        }
      },
    },
  };
</script>

<style lang="scss">
  .bonusPoints {
    width: 100%;

    &__header {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 20px;
      line-height: 21px;
      color: #ff4f79;
      margin: auto;
      margin-bottom: 40px;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 30px;
      line-height: 30px;
      color: #7a615a;
      margin: auto;
      margin-top: 20px;

      &__price {
        color: #36d5f2;
      }

      margin-bottom: 80px;
    }

    &__currencies {
      width: auto;
      grid-gap: 10px;
      // grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));

      // grid-auto-columns: minmax(250px,1fr);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      height: 100px;

      &__item {
        width: 100%;

        &__currency {
          height: 110px;
          width: 110px;
          cursor: pointer;
        }

        &__value {
          font-size: 25px;
          line-height: 25px;
          color: #7a615a;
          margin: auto;
          margin-top: -75px;
          margin-left: 25px;
          cursor: pointer;
        }
      }

      &__up {
        margin-top: -30px !important;
      }

      &__currencyUp {
        margin-top: -80px !important;
      }
    }

    &__button {
      width: 50%;
      margin: auto;
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__skip {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 18px;
      line-height: 21px;
      color: #ff4f79;
      margin: auto;
      margin-top: 40px;
      cursor: pointer;
    }

    &__correct {
      width: auto;

      &__text {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 30px;
        line-height: 30px;
        color: #36d5f2;
        margin: auto;
        margin-top: 20px;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .bonusPoints__currencies__item__value {
      font-size: 9px;
      line-height: 25px;
      color: #7a615a;
      margin-top: -38px;
      margin-left: 8px;
      cursor: pointer;
    }
    .bonusPoints__currencies {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .bonusPoints__currencies__item__currency {
      width: 40px;
      height: 40px;
    }
    .bonusPoints__currencies {
      grid-gap: 0;
      margin: 0 auto;
    }
  }
</style>
